import React from "react";
import Restaurang from "./Restaurang";
import { useStaticQuery, graphql } from "gatsby";

function Restauranger() {
  const data = useStaticQuery(graphql`
    {
      allWpRestaurang {
        nodes {
          restaurang {
            adress
            oppettider
            ovrigInfo
          }
          title
          id
          uri
        }
      }
    }
  `);

  const posts = data.allWpRestaurang.nodes;

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-around">
        {posts.map((post) => {
          return <Restaurang key={post.id} post={post} />;
        })}
      </div>
    </div>
  );
}

export default Restauranger;
