import React from "react";

function Waves() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 431.7 21.9"
      version="1.1"
      viewBox="0 0 431.7 21.9"
      xmlSpace="preserve"
      className={`text-yellow-400 fill-current`}
    >
      <path d="M431.7 12c-8.6 0-12.9-2.6-17.1-5.1-4.1-2.5-8.1-4.9-16.1-4.9s-11.9 2.4-16.1 4.9c-4.2 2.5-8.5 5.1-17.1 5.1s-12.9-2.6-17.1-5.1C344 4.4 340.1 2 332.1 2s-12 2.4-16.1 4.9c-4.2 2.5-8.5 5.1-17.1 5.1S286 9.4 281.8 6.9c-4.1-2.5-8.1-4.9-16.1-4.9-8 0-11.9 2.4-16.1 4.9-4.2 2.5-8.5 5.1-17.1 5.1-8.6 0-12.9-2.6-17.1-5.1-4.1-2.5-8.1-4.9-16.1-4.9s-11.9 2.4-16.1 4.9c-4.2 2.5-8.5 5.1-17.1 5.1-8.6 0-12.9-2.6-17.1-5.1-4.1-2.5-8.1-4.9-16.1-4.9S121 4.4 116.8 6.9c-4.2 2.5-8.5 5.1-17.1 5.1-8.7 0-13-2.6-17.2-5.1C78.3 4.4 74.4 2 66.4 2S54.5 4.4 50.3 6.9C46.1 9.4 41.8 12 33.2 12c-8.6 0-12.9-2.6-17.1-5.1C11.9 4.4 8 2 0 2V0c8.6 0 12.9 2.6 17.1 5.1 4.1 2.5 8.1 4.9 16.1 4.9s11.9-2.4 16.1-4.9C53.5 2.6 57.8 0 66.4 0s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9 8 0 11.9-2.4 16.1-4.9 4.2-2.5 8.5-5.1 17.1-5.1s12.9 2.6 17.1 5.1C154.1 7.6 158 10 166 10c8 0 11.9-2.4 16.1-4.9 4.2-2.5 8.5-5.1 17.1-5.1s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9 8 0 11.9-2.4 16.1-4.9C252.7 2.6 257 0 265.6 0s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9 8 0 11.9-2.4 16.1-4.9C319.1 2.6 323.4 0 332 0s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9s11.9-2.4 16.1-4.9c4.2-2.5 8.5-5.1 17.1-5.1s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9v2z"></path>
      <path d="M431.7 21.9c-8.6 0-12.9-2.6-17.1-5.1-4.1-2.5-8.1-4.9-16.1-4.9s-11.9 2.4-16.1 4.9c-4.2 2.5-8.5 5.1-17.1 5.1s-12.9-2.6-17.1-5.1c-4.1-2.5-8.1-4.9-16.1-4.9s-11.9 2.4-16.1 4.9c-4.2 2.5-8.5 5.1-17.1 5.1s-12.9-2.6-17.1-5.1c-4.1-2.5-8.1-4.9-16.1-4.9-8 0-11.9 2.4-16.1 4.9-4.2 2.5-8.5 5.1-17.1 5.1-8.6 0-12.9-2.6-17.1-5.1-4.1-2.5-8.1-4.9-16.1-4.9s-11.9 2.4-16.1 4.9c-4.2 2.5-8.5 5.1-17.1 5.1-8.6 0-12.9-2.6-17.1-5.1-4.1-2.5-8.1-4.9-16.1-4.9s-11.9 2.4-16.1 4.9c-4.2 2.5-8.5 5.1-17.1 5.1-8.6 0-12.9-2.6-17.1-5.1-4.1-2.5-8.1-4.9-16.1-4.9s-11.9 2.4-16.1 4.9c-4.2 2.5-8.5 5.1-17.1 5.1-8.6 0-12.9-2.6-17.1-5.1C11.9 14.2 8 11.9 0 11.9v-2c8.6 0 12.9 2.6 17.1 5.1 4.1 2.5 8.1 4.9 16.1 4.9s11.9-2.4 16.1-4.9c4.2-2.5 8.5-5.1 17.1-5.1s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9 8 0 11.9-2.4 16.1-4.9 4.2-2.5 8.5-5.1 17.1-5.1s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9 8 0 11.9-2.4 16.1-4.9 4.2-2.5 8.5-5.1 17.1-5.1s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9 8 0 11.9-2.4 16.1-4.9 4.2-2.5 8.5-5.1 17.1-5.1s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9 8 0 11.9-2.4 16.1-4.9 4.2-2.5 8.5-5.1 17.1-5.1s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9s11.9-2.4 16.1-4.9c4.2-2.5 8.5-5.1 17.1-5.1s12.9 2.6 17.1 5.1c4.1 2.5 8.1 4.9 16.1 4.9v2z"></path>
    </svg>
  );
}

export default Waves;
