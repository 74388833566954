import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Side from "./Side";

const Sides = () => {
  const data = useStaticQuery(graphql`
    {
      allWpSide {
        edges {
          node {
            mat {
              pris
              beskrivning
              vegetarisk
            }
            title
          }
        }
      }
    }
  `);
  const posts = data.allWpSide.edges;
  return (
    <div className="">
      <div className="food-type secondary">
        <h3 className="">Sides</h3>
      </div>
      <div className="flex flex-col md:flex-row justify-between text-center md:text-left">
        <div className="md:grid grid-cols-2 gap-8">
          {posts.map((post) => {
            return <Side post={post} key={post.id} />;
          })}
        </div>
        <div className="food-price"> </div>
      </div>
    </div>
  );
};

export default Sides;
