import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Ratt from "./Ratt";

const MainsSaluhallen = () => {
  const data = useStaticQuery(graphql`
    {
      allWpRatt(
        filter: {
          categories: {
            nodes: { elemMatch: { slug: { eq: "malmo-saluhall" } } }
          }
        }
      ) {
        edges {
          node {
            mat {
              pris
              beskrivning
              vegetarisk
            }
            title
          }
        }
      }
    }
  `);
  const posts = data.allWpRatt.edges;
  return (
    <div>
      <div className="food-type md:w-1/2 mx-auto">
        <h3 className="">Meny</h3>
        <p className="">
          Alla skålar serveras med varmt sushiris, gurka, rödkål, sojabönor,
          vårlök och sesam.
        </p>
      </div>
      {/* <div className="text-center py-2">
        Beställ säkert via appen{" "}
        <a
          href="https://cutt.ly/Pa-ris"
          rel="nofollow"
          title="Beställ via Weiq"
          className="underline"
        >
          WEIQ
        </a>
        .{" "}
      </div> */}
      <div className="md:grid grid-cols-2 gap-8 ">
        {posts.map((post) => {
          return <Ratt post={post} key={post.id} />;
        })}
      </div>
    </div>
  );
};

export default MainsSaluhallen;
