import React from "react";

function Side({ post }) {
  const { title } = post.node;
  const { beskrivning, pris, vegetarisk } = post.node.mat;
  return (
    <div className="flex flex-col justify-start text-left my-4 md:my-0">
      <header className="flex flex-row justify-between items-center flex-wrap">
        <h4 className="food-title text-lg">
          {title}
          {vegetarisk ? `` : ` `}
        </h4>

        <div className="food-price">
          <p>{pris}kr</p>
        </div>
      </header>
      <div className="pr-4 flex justify-start">
        <p>{beskrivning}</p>
      </div>
    </div>
  );
}

export default Side;
