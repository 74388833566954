import React from "react";

export default function Restaurang({ post }) {
  return (
    <div className="restaurant text-center mb-4 md:mb-0">
      <header>
        <h4 className="mb-4">{post.title}</h4>
      </header>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: post.restaurang?.adress,
          }}
        />
      </p>
      <p className="my-2">
        <div>{post.restaurang.oppettider ? "Öppettider:" : ""}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: post.restaurang?.oppettider,
          }}
        />
      </p>
      <p className="my-2">
        <div
          dangerouslySetInnerHTML={{
            __html: post.restaurang?.ovrigInfo,
          }}
        />
      </p>
    </div>
  );
}
