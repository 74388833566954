import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Waves from "../components/common/Waves";
import MainsSaluhallen from "../components/food/MainsSaluhallen";

import Sides from "../components/food/Sides";
// import Instagram from "../components/index/Instagram";
import Restauranger from "../components/index/Restauranger";
import Welcome from "../components/index/Welcome";
import img from "../images/pa-ris-weiq-badge-take-away.png";
import { Link } from "gatsby";

function IndexPage(props) {
  // const [restaurant, setRestaurant] = useState("saluhallen");
  console.log(props.location.search);

  return (
    <Layout>
      <SEO
        keywords={[`Poké`, `Bowl`, `Saluhall`, `Saluhallen`]}
        title="PåRis - Modern Poké Bowl i Malmö Saluhall"
      />

      <section className="mt-16 max-w-3xl px-4 mx-auto">
        <div className="my-12 mx-8">
          <Waves />
        </div>
        <div className="mx-2">
          <Welcome />
        </div>
        <div className="my-12 mx-8">
          <Waves />
        </div>
      </section>

      <section id="meny" className="py-4 max-w-5xl px-8 md:px-4 mx-auto">
        <div className="space-x-4 text-center"></div>

        <div className="mt-6 mb-12 h-full">
          <MainsSaluhallen />
        </div>

        <div className="my-2 grid md:grid-cols-1 gap-8">
          <div className="">
            <Sides />
          </div>
        </div>
        <div className="text-center mt-6">
          <p>Har du några allergier? Fråga oss.</p>
        </div>
        <div className="py-8 max-w-xl mx-auto">
          <Link to="https://weiq.tech/gapp?seller=8fb57382-22f2-4878-acbc-4e49e0074ffd">
            <img src={img} alt="Beställ" />
          </Link>
        </div>
      </section>

      <section className="mt-6 max-w-3xl px-4 mx-auto">
        <div className=" my-6 mx-8">
          <Waves />
        </div>
      </section>

      <section
        id="restauranger"
        className="py-4 max-w-5xl px-8 md:px-4 mx-auto"
      >
        <Restauranger />
      </section>
      {/* <section className="my-8 px-8">
        <Instagram />
      </section> */}
    </Layout>
  );
}

export default IndexPage;
