import React from "react";

function Welcome() {
  return (
    <div className="text-xl md:text-2xl text-center">
      Välkommen till PåRis! Hos oss äter du hawaiiska poké bowls så som de var
      tänkta att ätas, med ljummet ris och marinerad fisk, noggrant utvalda
      tillbehör och smakrik salsa. Vi finns på Malmö Saluhall och i Hyllie.
    </div>
  );
}

export default Welcome;
